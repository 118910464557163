import { useStaticQuery, graphql } from "gatsby";

import { formatIndexPageData } from "../../helpers/data/data.helper";

import { IndexPageData } from "./useIndexPageData.types";

export default (): IndexPageData => {
  const { contentfulPagina } = useStaticQuery(
    graphql`
      query IndexPage {
        contentfulPagina(slug: { eq: "92023a67-6bf5-46c4-ab36-d8109a289139" }) {
          id
          title
          content {
            ... on ContentfulHeader {
              id
              title
              subtitle
              intro {
                raw
              }
              image {
                title
                description
                gatsbyImageData(quality: 100, placeholder: BLURRED, formats: AUTO, layout: FULL_WIDTH)
              }
              link {
                title
                isInternal
                page {
                  slug
                }
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulContentBlok {
              id
              title
              description {
                raw
              }
              link {
                title
                isInternal
                page {
                  slug
                }
              }
              image {
                title
                description
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: AUTO
                  aspectRatio: 1.78
                  layout: FULL_WIDTH
                )
              }
              isAlternate
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return formatIndexPageData(contentfulPagina);
};
