import React from "react";
import { isNil } from "ramda";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { Link } from "../Link/Link";
import { LinkType } from "../Link/Link.types";
import { ImageAsset } from "../ImageAsset/ImageAsset";

import { HeroProps } from "./Hero.types";

export const Hero: React.FC<HeroProps> = ({ title, subtitle, image, intro, link }: HeroProps) => (
  <section className="m-hero">
    <div className="m-hero__block">
      <ImageAsset title={image.title} image={image.gatsbyImageData} cn="m-hero__image" />
    </div>
    <div className="m-hero__block u-wrapper">
      <div className="m-hero__content">
        <h2 className="u-heading-xlarge u-gold m-hero__title">{title}</h2>
        <p className="u-heading-xsmall u-ragoon m-hero__subtitle">{subtitle}</p>
        <div className="m-hero__description">{documentToReactComponents(JSON.parse(intro))}</div>
        {!isNil(link) && (
          <Link
            title="link.tiltle"
            href={link.href}
            type={link.isInternal ? LinkType.Internal : LinkType.External}
            cn="m-hero__link a-link u-heading-small"
          >
            {link.title}
          </Link>
        )}
      </div>
    </div>
  </section>
);
