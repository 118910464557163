import React, { ReactNode } from "react";

import Layout from "../components/Layout/Layout";
import { Hero } from "../components/Hero/Hero";
import { SEO } from "../components/Seo/Seo";
import { ContentBlock } from "../components/ContentBlock/ContentBlock";

import useIndexPageData from "../hooks/useIndexPageData/useIndexPageData";
import { IndexPageData } from "../hooks/useIndexPageData/useIndexPageData.types";
import { getLink } from "../helpers/data/data.helper";
import { ContentfulBlock } from "../types/content-types";
import { isNil } from "ramda";

const IndexPage = ({ location }): ReactNode => {
  const { title, hero, blocks }: IndexPageData = useIndexPageData();

  return (
    <Layout location={location}>
      <SEO title={title} lang="nl" />
      <Hero
        title={hero.title}
        subtitle={hero.subtitle}
        intro={hero.intro.raw}
        image={hero.image}
        {...(!isNil(hero.link) && {
          link: getLink(hero.link),
        })}
      />
      <section className="u-wrapper">
        <h2 className="u-visibility-hidden">Pagina content</h2>
        {blocks.map(
          (block: ContentfulBlock): ReactNode => (
            <ContentBlock
              key={block.id}
              title={block.title}
              description={block.description}
              isAlternate={block.isAlternate}
              image={block.image}
              {...(!isNil(block.link) && {
                link: getLink(block.link),
              })}
            />
          )
        )}
      </section>
    </Layout>
  );
};

export default IndexPage;
